





































@import '~@/assets/styles/helpers/_variables.scss';

.closeButton {
    color: $colorPrimaryDarken;
    border: 3px solid $colorPrimaryDarken;
    border-radius: 16px;
    font-size: 16px;
    margin-top: 4px;


    &:hover {
      color: white;
      border: 3px solid white;
    }
  }
